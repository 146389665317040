
import { PageProps } from '@/types';
import Head from "@/Molecules/Inertia/Head";
import {relativeRoute} from "@/helpers/relativeRoute";

export default function Home({ obituaries, articles}: PageProps<{ obituaries: [], articles: [] }>) {

  return (
    <>
      <Head title="404 Pagina niet gevonden"/>

      <section className={'py-10'}>
        <div className="container mx-auto flex flex-col items-center">
          <h2>Deze pagina kon niet gevonden worden</h2>
          <p>Het lijkt erop dat de pagina die je zoekt niet (meer) bestaat. Maar geen zorgen! Hier zijn een paar
            suggesties om je weer op weg te helpen:<br />
          </p>
            <ul className="list-disc items-center">
              <li><a href={relativeRoute('home')}>Ga terug naar de homepage</a></li>
              <li><a href={relativeRoute('obituary.index')}>Zoek een overlijdensbericht</a></li>
              <li><a href={relativeRoute('contact')}>Neem contact op</a> als je denkt dat er iets mis is gegaan of als je hulp nodig hebt.</li>
            </ul>
          <p><br/><br />
            We hopen dat je snel vindt wat je zoekt. Bedankt voor je bezoek aan Mensenlinq.<br />
            Groet, het Mensenlinq team
          </p>
          <a className={'btn mt-5'} href={relativeRoute('home')}>Naar de homepagina</a>
        </div>
      </section>


    </>
  );
}
